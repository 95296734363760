import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import SignupHero from '../components/heros/signup-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import GenericPage from '../components/containers/generic-page'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class AboutPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Navigation />

        <GenericPage>
          <h1>About UIUX Tips...</h1>
          <p>The website is a side project created by myself to educate those who need a helping hand with design. I started out many years ago as a developer that wanted to create his own ideas, but quickly found how important design was in the entire process.</p>
          <p>After realising how important design was I started to focus my efforts on creating lovely designs. It's still a process I'm learning, but I feel as if I'm getting better with each design. That's where this website came from. It was a place to pass that knowledge on to others.</p>
        </GenericPage>

        <div className="">
          <EmailCtaSection />
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const AboutQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const SectionHeadline = styled.h2 `
  margin-bottom: 48px;
`

const TipCount = styled.div `
  color: #7A89FF;
  display: inline-block;
`

const YouTubeVideo = styled.iframe `
  border-radius: 8px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`

const FeatureList = styled.ul `
  display: flex;
  flex-wrap: wrap;
`